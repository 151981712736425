<template>
  <div class="objects">
    <admin-header
      title="Нестационарные ТО"
      :back="false"
    />
    <div class="flex mb-6 mt-7">
      <router-link
        class="flex align-center sulguni rocky--text mr-6"
        to="add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          class="mr-2"
          icon="add"
          size="16"
        />
        <div style="margin-top: -2px;">
          Добавить вручную
        </div>
      </router-link>
      <div
        class="rocky--text flex pointer"
        @click="openModalUpload"
      >
        <r-icon
          class="mr-2"
          icon="add"
          size="16"
        />
        <div style="margin-top: -2px;">
          Загрузить данные
        </div>
      </div>
    </div>
    <div class="flex mb-6 align-center">
      <r-select
        class="flex-1 mr-6"
        :items="sortType"
        label="Тип объекта"
        v-model="selectedSortType"
      />
      <r-select
        class="flex-1 mr-6"
        :items=" [
          {
            id: 'BY_NAME',
            title: 'По названию'
          },
          {
            id: 'BY_SQUARE',
            title: 'По площади'
          }
        ]"
        label="Сортировать"
        v-model="selectedSorting"
      />
      <r-input
        class="flex-1 mr-6"
        v-model.trim="search"
        label="Поиск"
        before-icon="search"
        size="eluno"
        :is-clear-model-string="true"
      />
      <r-button-simple
        size="larishae"
        icon="icon-view"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano text-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
          :balloon-component="BalloonCard"
        >
          <div v-if="filteredObjects.length > 0">
            <ymap-marker
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
              :coords="[marker.lat, marker.lng]"
              :marker-id="`marker_${marker.id}+${index}`"
              @click="onMarkerClick(marker.id)"
              @balloonopen="bindListener"
              :icon="!!Number(marker.contractConclude) ? $markerIcon('mapPinRed') : $markerIcon('mapPin')"
              :options="{
                hideIconOnBalloonOpen: false,
                iconColor: !!Number(marker.contractConclude) ? '#E14761' : '#3D75E4'
              }"
              cluster-name="main"
              :balloon-component-props="{
                marker: marker,
                openDeleteModal: openDeleteModal
              }"
            />
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6"
      >
        <card
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
      </div>
      <not-found v-if="countObjects < 1" />
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import Loader from '@/components/Loader.vue';
import Card from '../components/Card';
import { wordMatch } from '../helpers/utils';
import NotFound from '../components/NotFound';
import RirMap from '../components/RirMap';
import BalloonCard from '../components/BalloonCard';
import ModalUpload from '../components/ModalUpload';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    AdminHeader,
    Card,
    NotFound,
    RirMap,
    BalloonCard
  },
  data() {
    return {
      timeoutId: null,
      selectedView: 'table',
      searchText: '',
      selectedSorting: 'BY_NAME',
      sortType: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: '1',
          title: 'Занятые'
        },
        {
          id: '0',
          title: 'Свободные'
        }
      ],
      selectedSortType: 'ALL',
      isShowMap: false,
      selectedMarkerId: null
    };
  },
  computed: {
    BalloonCard() {
      return BalloonCard;
    },
    isLoading() {
      return this.$store.state.nto.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.nto.allObjects;

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => ((el.typeS ? (el.typeS.toLowerCase()
            .indexOf(this.searchText.toLowerCase()) >= 0) : false)
                || (el?.address ? (el?.address.toLowerCase()
                  .indexOf(this.searchText.toLowerCase()) >= 0) : false)
          )
        );
      }
      if (this.selectedSortType) {
        if (this.selectedSortType !== 'ALL') {
          list = list.filter(el => el.contractConclude === this.selectedSortType);
        }
      }
      if (this.selectedSorting) {
        // console.log(this.selectedSorting);
        if (this.selectedSorting === 'BY_NAME') {
          list = list.sort((a, b) => a.typeS?.localeCompare(b.typeS));
        }
        if (this.selectedSorting === 'BY_DATE') {
          list = list.sort((a, b) => new Date(b.dateToS) - new Date(a.dateToS));
        }
        if (this.selectedSorting === 'BY_SQUARE') {
          list = list.sort((a, b) => b.areaS - a.areaS);
        }
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllObjects');
  },
  methods: {
    async openDeleteModal(id) {
      await this.$refs.modal.openModal(DeleteModal, {
        title: 'Удалить НТО?',
        action: () => { this.delete(id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(result => {
        const r = this.$router.resolve({
          name: 'index'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    },
    openModalUpload() {
      this.$refs.modal.openModal(ModalUpload);
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },
    onMapClick() {
      this.selectedMarkerId = null;
    },
    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({ name: 'Edit', params: { id: this.selectedMarkerId } });
    },
    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
</style>
